import '../scss/main.scss'; // eslint-disable-line import/no-relative-parent-imports, import/no-unassigned-import

import initForms from './modules/form';
import megaMenu from './modules/megamenu';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function loadPolyfills (): Promise<any[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Array<Promise<any>> = [];

    if (!window.fetch) {
        promises.push(import('unfetch')); // window.fetch()
    }

    if (window.msCrypto) { // only exists in IE11
        promises.push(import('picturefill')); // <picture> tag
    }

    return Promise.all(promises);
}

function dropdownOnclickToggle (dropdownToggleButton: HTMLElement, dropdownElement: HTMLElement): void {
    for (const dropdownLink of dropdownElement.querySelectorAll<HTMLElement>('button')) {
        if (dropdownLink === dropdownToggleButton) {
            if (dropdownElement) {
                dropdownElement.classList.toggle('open');
            }
            if (dropdownLink?.getAttribute('aria-expanded') === 'false') {
                dropdownLink?.setAttribute('aria-expanded', 'true');
            } else {
                dropdownLink?.setAttribute('aria-expanded', 'false');
            }
        } else {
            dropdownElement?.classList.remove('open');
            dropdownLink.setAttribute('aria-expanded', 'false');
        }
    }
}

function initShowMorePanelButtons (): void {
    for (const panelContainer of document.querySelectorAll<HTMLElement>('.panel-container')) {
        const showMoreButton = panelContainer.querySelector<HTMLElement>('.panel-show-more button');
        showMoreButton?.addEventListener('click', function () {
            const url = showMoreButton.getAttribute('data-show-more-url');
            if (url) {
                const xhr = new XMLHttpRequest(); // eslint-disable-line no-restricted-globals
                xhr.open('GET', url);
                xhr.onload = function (): void {
                    if (xhr.status === 200) {
                        const containerElement = showMoreButton.parentElement?.parentElement;
                        if (containerElement) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            const json: ListResponse = JSON.parse(xhr.responseText);
                            const listContainer = showMoreButton.parentElement?.parentElement?.querySelector<HTMLElement>('.panel-list-container');
                            showMoreButton.parentElement?.remove();
                            const id = 'panel-link-' + Math.floor(Math.random() * 0xFFFFFFFF).toString(16);
                            const htmlList = json.htmlList.replace(
                                /\sdata-panel-link\s*=\s*"/,
                                ` id="${id}" data-panel-link\\s+="`);
                            if (listContainer) {
                                listContainer.insertAdjacentHTML(
                                    'beforeend',
                                    htmlList
                                );
                            } else {
                                containerElement.appendChild(makeHtmlNodeForList(htmlList));
                            }
                            containerElement.insertAdjacentHTML(
                                'beforeend',
                                json.htmlButton
                            );
                            containerElement?.querySelector<HTMLElement>('#' + id)?.focus();
                            const searchInfoElement = panelContainer.parentElement?.querySelector<HTMLElement>('.search-results-info');
                            if (searchInfoElement) {
                                searchInfoElement.innerHTML = json.infoText;
                            }
                        }
                        initShowMorePanelButtons(); // @todo stop applying click handler to all buttons again
                    }
                };
                xhr.send();
            }
        });
    }
}

function initSkipLinkFocus (): void {
    const skipLinkContainer = document.querySelector<HTMLElement>('#quick-navigation-wrap');
    if (skipLinkContainer) {
        skipLinkContainer.addEventListener('focusin', function (this: HTMLElement) {
            this.setAttribute('class', 'focus-in');
        });
        skipLinkContainer.addEventListener('focusout', function (this: HTMLElement) {
            this.setAttribute('class', 'sr-only');
        });
    }
}

function initSearchButton (): void {
    const searchButton = document.getElementById('site-search-button');
    const searchInput: HTMLInputElement | null = document.getElementById('site-search-input') as HTMLInputElement;
    const searchForm: HTMLFormElement | null = document.getElementById('site-search-form') as HTMLFormElement;

    if (searchButton && searchInput) {
        searchButton.addEventListener('click', function () {
            if (searchInput.value) {
                searchForm.submit();
            } else {
                searchInput.classList.toggle('active');
            }
        });
    }
}

function getFilteredSearchResults (url: string, data: FormData): void {
    const panelContainer = document.querySelector<HTMLElement>('.panel-container');
    const showMorebutton = panelContainer?.querySelector<HTMLElement>('.panel-show-more');

    if (panelContainer) {
        panelContainer.innerHTML = '';
        const xhr = new XMLHttpRequest(); // eslint-disable-line no-restricted-globals
        xhr.open('POST', url);
        xhr.onload = function (): void {
            if (xhr.status === 200) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const response: ListResponse = JSON.parse(xhr.responseText);
                showMorebutton?.remove();
                if (response.htmlList.replace(/\s/g, '')) {
                    panelContainer.appendChild(makeHtmlNodeForList(response.htmlList));
                }
                panelContainer.insertAdjacentHTML('beforeend', response.htmlButton);
                initShowMorePanelButtons();
                const searchInfoElement = panelContainer.parentElement?.querySelector<HTMLElement>('.search-results-info');
                if (searchInfoElement) {
                    searchInfoElement.innerHTML = response.infoText;
                }
            }
        };
        xhr.send(data);
    }
}

function makeHtmlNodeForList (htmlList: string): Node {
    const listContainer = document.createElement('ul');
    listContainer.className = 'panel-list-container';
    listContainer.insertAdjacentHTML('beforeend', htmlList);
    return listContainer;
}

function closeAllDropdowns (exclude: HTMLElement|null = null): void {
    const dropdownElements = document.querySelectorAll<HTMLElement>('.dropdown');
    for (const dropdownElement of dropdownElements) {
        if (dropdownElement !== exclude) {
            dropdownElement?.classList.remove('open');
            for (const dropdownToggleButton of dropdownElement.querySelectorAll('button')) {
                dropdownToggleButton.setAttribute('aria-expanded', 'false');
            }
        }
    }
}

function setupDom (): void {
    window.addEventListener('click', function (event) {
        if (event.target instanceof Element && !event.target.closest('.dropdown')) {
            closeAllDropdowns();
        }
    });

    const dropdownElements = document.querySelectorAll<HTMLElement>('.dropdown');
    for (const dropdownElement of dropdownElements) {
        for (const dropdownToggleButton of dropdownElement.querySelectorAll('button')) {
            dropdownToggleButton.addEventListener('click', (event) => {
                event.preventDefault();
                closeAllDropdowns(dropdownElement);
                dropdownOnclickToggle(dropdownToggleButton, dropdownElement);
            });
        }
    }

    const bannerElements = document.querySelectorAll<HTMLElement>('.ce-banner');
    for (const bannerElement of bannerElements) {
        bannerElement.addEventListener('click', function (event): void {
            const bannerLink = (event.currentTarget as HTMLElement).querySelector<HTMLAnchorElement>('a');
            if (bannerLink && window.getSelection()?.toString() === '') {
                if (event.ctrlKey) {
                    window.open(bannerLink.href);
                } else {
                    bannerLink.click();
                }
                event.stopPropagation();
            }
        });
    }

    for (const panel of document.querySelectorAll<HTMLElement>('.panel')) {
        const linkElement = panel.querySelector<HTMLElement>('[data-panel-link]');
        if (linkElement) {
            panel.addEventListener('click', () => {
                linkElement.click();
            });
        }
    }

    const recipeSearchForms = document.querySelectorAll<HTMLFormElement>('.recipe-search-filter');
    for (const searchForm of recipeSearchForms) {
        getFilteredSearchResults('?type=7383', new FormData(searchForm));

        const searchFilterSelect = searchForm.querySelector<HTMLSelectElement>('select');
        if (searchFilterSelect) {
            searchFilterSelect.addEventListener('change', function () {
                getFilteredSearchResults('?type=7383', new FormData(searchForm));
            });
        }
    }

    initShowMorePanelButtons();
    initSkipLinkFocus();
    megaMenu('.header-menu');
    initForms();
    initSearchButton();
}

function workOnDom (): void {
    polyfillsReady
        .then(() => setupDom())
        .catch((error) => console.error(error));
}

const polyfillsReady = loadPolyfills();
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
}
